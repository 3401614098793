import classNames from "classnames";
import React from "react";

const Text = ({ children, onClick, className = "" }) => {
  return (
    <p className={classNames("", className)} onClick={onClick}>
      {children}
    </p>
  );
};

export default Text;
