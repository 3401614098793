import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomButton from "../../../Shared/CustomButton";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Divider } from "@mui/material";
import Text from "../../../Shared/Text";
import CustomDiv from "../../../Shared/CustomDiv";
import CustomInput from "../../../Shared/CustomInput";

const Attachments = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        icon={<ClockCircleOutlined />}
        type="info"
        onClick={handleClick}
        className="!bg-slate-200 !text-black hover:!bg-slate-300 w-full text-start"
      >
        Attachments
      </CustomButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-1"
      >
        <Text className="text-center my-1">Attach from..</Text>
        <Divider />
        <CustomDiv className="flex flex-col p-2 !text-start">
          <MenuItem className="!rounded">Computer</MenuItem>
          <MenuItem className="!rounded">Aara Clouds</MenuItem>
          <MenuItem className="!rounded">Google Drive</MenuItem>
          <MenuItem className="!rounded">Dropbox</MenuItem>
          <MenuItem className="!rounded">Box</MenuItem>
          <MenuItem className="!rounded">One Drive</MenuItem>
          <Divider />

          <CustomInput label="Attach a link" />
          <CustomButton className="!my-2">Attach</CustomButton>
          <Divider />
          <Text className="px-2 text-sm">
            Tip: You can drag and drop files and links <br /> onto cards to
            upload them.
          </Text>
        </CustomDiv>
      </Menu>
    </>
  );
};

export default Attachments;
