import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import CustomButton from "../../../Shared/CustomButton";
import Text from "../../../Shared/Text";
import CustomInput from "../../../Shared/CustomInput";
import CustomDiv from "../../../Shared/CustomDiv";
import { Divider } from "@mui/material";

const Checklist = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        onClick={handleClick}
        className="!bg-slate-200 !text-black hover:!bg-slate-300 w-full text-start"
      >
        Checklist
      </CustomButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-1"
      >
        <Text className="text-center my-1">Checklist</Text>
        <Divider />
        <CustomDiv className="p-2 flex flex-col gap-2">
          <CustomInput label="Title" className="w-full" />
          <CustomButton className="mx-10">Add</CustomButton>
        </CustomDiv>
      </Menu>
    </>
  );
};

export default Checklist;
