import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Avatar, Checkbox, Divider, FormControl, Select } from "@mui/material";
import Text from "../../../Shared/Text";
import CustomInput from "../../../Shared/CustomInput";
import CustomDiv from "../../../Shared/CustomDiv";
import { CalendarMonth, Circle, Sort } from "@mui/icons-material";

const FilterMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="text"
        startIcon={<Sort />}
        onClick={handleClick}
        className="!bg-white !px-2 !bg-opacity-30 !capitalize !mr-1 !text-black"
      >
        Filter
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-1"
      >
        <Text className="text-center my-1">Filters</Text>
        <Divider />
        <CustomDiv className="p-4 flex flex-col w-96">
          <span>
            <CustomInput label="Keywords" className="w-full" />
            <Text className="text-xs m-1">
              Search cards, members, labels, and more.
            </Text>
          </span>
          <Text className="text-xs font-semibold m-1"> Members</Text>
          <span className="flex items-center gap-2 cursor-pointer">
            <Checkbox />
            <Avatar src="broken.jpg" className="!h-6 !w-6" />
            <Text className="font-semibold">No Memebers</Text>
          </span>
          <span className="flex items-center gap-2 cursor-pointer">
            <Checkbox />
            <Avatar
              src="broken.jpg"
              alt="MS"
              className="!h-7 !w-7 !text-sm !bg-orange-600"
            />
            <Text className="font-semibold">Mani Kant Sharma</Text>
          </span>
          <Text className="text-xs font-semibold m-1">Due Date</Text>
          <span className="flex items-center gap-2 cursor-pointer">
            <Checkbox />
            <CalendarMonth className="text-gray-500" />
            <Text className="font-semibold">No Dates</Text>
          </span>
          <span className="flex items-center gap-2 cursor-pointer">
            <Checkbox />
            <ClockCircleOutlined className="text-red-500" />
            <Text className="font-semibold">Overdue</Text>
          </span>
          <span className="flex items-center gap-2 cursor-pointer">
            <Checkbox />
            <ClockCircleOutlined className="text-yellow-500" />
            <Text className="font-semibold">Due in the next day</Text>
          </span>
          <span className="flex items-center gap-2 cursor-pointer">
            <Checkbox />
            <ClockCircleOutlined className="text-gray-500" />
            <Text className="font-semibold">Due in the next week</Text>
          </span>
          <span className="flex items-center gap-2 cursor-pointer">
            <Checkbox />
            <ClockCircleOutlined className="text-gray-500" />
            <Text className="font-semibold">Due in the next month</Text>
          </span>
          <span className="flex items-center gap-2 cursor-pointer">
            <Checkbox />
            <Text className="font-semibold">Marked as complete</Text>
          </span>
          <span className="flex items-center gap-2 cursor-pointer">
            <Checkbox />
            <Text className="font-semibold">Not marked as complete</Text>
          </span>
          <Text className="text-xs font-semibold m-1">Labels</Text>
          <span className="flex items-center cursor-pointer ">
            <Checkbox />
            <span className="bg-green-200 w-full p-1">
              <Circle className="!text-green-500 m-1 !text-lg" />
            </span>
          </span>
          <span className="flex items-center cursor-pointer ">
            <Checkbox />
            <span className="bg-yellow-200 w-full p-1">
              <Circle className="!text-yellow-500 m-1 !text-lg" />
            </span>
          </span>
          <span className="flex items-center cursor-pointer ">
            <Checkbox />
            <span className="bg-yellow-200 w-full p-1">
              <Circle className="!text-yellow-500 m-1 !text-lg" />
            </span>
          </span>
          <FormControl fullWidth size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              size="small"
              defaultValue={1}
              className="w-full my-2"
              onChange={(event) => setValue(event.target.value)}
            >
              <MenuItem value={1}>
                <span>
                  <Text>Any Match</Text>
                  <Text className="text-xs">
                    Matches any label and any members
                  </Text>
                </span>
              </MenuItem>
              <MenuItem value={2}>
                <span>
                  <Text>Exact Match</Text>
                  <Text className="text-xs">
                    Matches all label and all members
                  </Text>
                </span>
              </MenuItem>
            </Select>
          </FormControl>
        </CustomDiv>
      </Menu>
    </>
  );
};

export default FilterMenu;
