import React, { useState } from "react";
import CustomDiv from "../Shared/CustomDiv";
import MainHeader from "../Shared/MainHeader";
import SidebarNew from "../Shared/Sidebar2";

const Layouts = ({ component }) => {
  const [workspaceName, setWorkspaceName] = useState("");
  return (
    <CustomDiv
      className="min-h-screen flex-col bg-no-repeat bg-cover flex bg-transparent"
      style={{
        backgroundImage: `url("https://img.freepik.com/free-vector/gradient-glassmorphism-background_23-2149447861.jpg?w=740&t=st=1676874027~exp=1676874627~hmac=d849676d9f23a0b441cea43951cde1ad643c6f5a8adaae2508a8e857d20291c4")`,
      }}
    >
      <MainHeader setWorkspaceName={setWorkspaceName}/>
      <CustomDiv className="flex h-full">
        <SidebarNew workspaceName={workspaceName} />
        <CustomDiv className="site-layout backdrop-blur w-full">
          {/* <Head collapsed={collapsed} setCollapsed={setCollapsed} /> */}
          <CustomDiv className="w-full h-[93vh] overflow-y-auto">
            {component}
          </CustomDiv>
        </CustomDiv>
      </CustomDiv>
    </CustomDiv>
  );
};
export default Layouts;
