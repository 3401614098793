import { Lock, People, WrongLocation } from "@mui/icons-material";
import { Button, Divider } from "@mui/material";
import React from "react";
import BoardHeader from "../../Components/Bords/BoardHeader";
import CustomDiv from "../../Shared/CustomDiv";
import Text from "../../Shared/Text";

const Settings = () => {
  return (
    <>
      <BoardHeader />
      <CustomDiv className="mx-5">
        <CustomDiv className="flex flex-col m-5 !mx-10">
          <Text className="text-xl font-semibold my-3">Workspace settings</Text>
          <Text className="my-2 font-semibold">Workspace Visibility</Text>
          <Divider />
          <span className="flex justify-between my-2">
            <span className="flex items-center">
              <Lock className="!text-red-500 !text-base" />
              <Text>
                Private - This Workspace is private. It's not indexed or visible
                to those outside the Workspace.
              </Text>
            </span>
            <span>
              <Button
                className="!capitalize !bg-white !bg-opacity-20"
                size="small"
              >
                Change
              </Button>
            </span>
          </span>

          <Text className="my-2 font-semibold">
            Workspace membership restrictions
          </Text>
          <Divider />
          <span className="flex justify-between my-2">
            <span className="flex items-center">
              <Text>Anyone can be added to this Workspace.</Text>
            </span>
            <span>
              <Button
                className="!capitalize !bg-white !bg-opacity-20"
                size="small"
              >
                Change
              </Button>
            </span>
          </span>
          <Text className="my-2 font-semibold">
            Board creation restrictions
          </Text>
          <Divider />
          <span className="flex justify-between my-2">
            <span>
              <Text>
                Any Workspace member can create{" "}
                <WrongLocation className="!text-green-500 !text-base" /> public
                boards.
              </Text>
              <Text>
                Any Workspace member can create{" "}
                <People className="!text-yellow-500 !text-base" /> Workspace
                visible boards.
              </Text>
              <Text>
                Any Workspace member can create{" "}
                <Lock className="!text-red-500 !text-base" /> private boards.
              </Text>
            </span>

            <span>
              <Button
                className="!capitalize !bg-white !bg-opacity-20"
                size="small"
              >
                Change
              </Button>
            </span>
          </span>
          <Text className="my-2 font-semibold">
            Board deletion restrictions
          </Text>
          <Divider />
          <span className="flex justify-between my-2">
            <span>
              <Text>
                Any Workspace member can Delete{" "}
                <WrongLocation className="!text-green-500 !text-base" /> public
                boards.
              </Text>
              <Text>
                Any Workspace member can Delete{" "}
                <People className="!text-yellow-500 !text-base" /> Workspace
                visible boards.
              </Text>
              <Text>
                Any Workspace member can Delete{" "}
                <Lock className="!text-red-500 !text-base" /> private boards.
              </Text>
            </span>

            <span>
              <Button
                className="!capitalize !bg-white !bg-opacity-20"
                size="small"
              >
                Change
              </Button>
            </span>
          </span>
          <Text className="my-2 font-semibold">Sharing boards with guests</Text>
          <Divider />
          <span className="flex justify-between my-2">
            <span className="flex items-center">
              <Text>
                Anybody can send or receive invitations to boards in this
                Workspace.
              </Text>
            </span>
            <span>
              <Button
                className="!capitalize !bg-white !bg-opacity-20"
                size="small"
              >
                Change
              </Button>
            </span>
          </span>
          <Text className="my-2 font-semibold">
            Slack workspaces restrictions
          </Text>
          <Divider />
          <span className="flex justify-between my-2">
            <span className="flex items-center">
              <Text>
                Any Workspace member can link and unlink this Trello Workspace
                with Slack workspace
              </Text>
            </span>
            <span>
              <Button
                className="!capitalize !bg-white !bg-opacity-20"
                size="small"
              >
                Change
              </Button>
            </span>
          </span>
        </CustomDiv>
      </CustomDiv>
    </>
  );
};

export default Settings;
