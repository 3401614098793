import Bords from "../Components/Bords";
import BoardsManagement from "../Pages/Bords";
import Members from "../Pages/Members";
import Settings from "../Pages/Settings";
import Workspace from "../Pages/Workspace";

export const routes = [
  {
    id: 1,
    path: "/workspace/boards-management",
    element: <Workspace component={<BoardsManagement />} />,
  },
  {
    id: 2,
    path: "/workspace/members",
    element: <Workspace component={<Members />} />,
  },
  {
    id: 3,
    path: "/workspace/:workspaceName",
    element: <Workspace component={<BoardsManagement />} />,
  },
  {
    id: 4,
    path: "/workspace/boards/:boardName",
    element: <Workspace component={<Bords />} />,
  },
  {
    id: 5,
    path: "/workspace/settings",
    element: <Workspace component={<Settings />} />,
  },
];
