import * as React from "react";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Avatar, Divider, IconButton } from "@mui/material";
import Text from "../Text";
import classNames from "classnames";
import {
  ArrowBackIos,
  Assessment,
  People,
  Settings,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CreateBoard from "../MainHeader/CreateMenu/CreateBoard";
import InviteMembers from "../../Components/Members/InviteMembers";

const SidebarNew = () => {
  const [collapse, setCollapse] = React.useState(false);
  const workspaceName = localStorage.getItem("current-workspace");
  const navigate = useNavigate();

  const currentWorkspace =
    localStorage.getItem("current-workspace") === "default-workspace"
      ? ["default-board", "new-board", "testing-board", "project-board"]
      : ["aara-board", "cloud-board", "erp-board"];

  return (
    <List
      component="nav"
      className={classNames(
        "!w-full max-w-[230px] min-h-[93.8vh] z-50 shadow transition-all duration-300 backdrop-blur-2xl",
        collapse ? "!max-w-[20px] transition-all duration-500" : ""
      )}
    >
      <ListItem className="flex items-center gap-2 !h-26 !text-black">
        {collapse ? null : (
          <>
            <Avatar
              className="!rounded !bg-gradient-to-t !from-yellow-600 !to-red-600"
              src="broken.jpg"
              alt="Project Manager"
            />
            <span>
              <Text className="whitespace-nowrap font-semibold !capitalize">
                {workspaceName?.replace("-", " ")}
              </Text>
              <Text className="whitespace-nowrap">Free</Text>
            </span>
          </>
        )}
        <IconButton
          size="small"
          onClick={() => setCollapse(!collapse)}
          className={classNames(
            "",
            collapse
              ? "!-left-[10px] !border !p-0 !backdrop-blur-2xl !border-solid !rounded-full !rotate-180"
              : ""
          )}
        >
          <ArrowBackIos className="!p-0.5 !border-r-4 !border-solid !border-blue-500 !border-opacity-0" />
        </IconButton>
      </ListItem>
      <Divider />
      {collapse ? null : (
        <>
          <ListItemButton
            className={classNames(
              "!p-1 !border-r-4 !border-solid !border-blue-500 !border-opacity-0 !px-2.5",
              window.location.pathname === "/workspace/boards-management"
                ? "!border-opacity-100"
                : ""
            )}
            onClick={() => navigate("/workspace/boards-management")}
          >
            <ListItemIcon>
              <Assessment />
            </ListItemIcon>
            <ListItemText primary="Boards" />
          </ListItemButton>
          <ListItemButton
            className={classNames(
              "!p-1 !border-r-4 !border-solid !border-blue-500 !border-opacity-0 !px-2.5",
              window.location.pathname === "/workspace/members"
                ? "!border-opacity-100"
                : ""
            )}
            onClick={() => navigate("/workspace/members")}
          >
            <ListItemIcon>
              <People />
            </ListItemIcon>
            <ListItemText primary="Members" />
            <InviteMembers from="Sidebar" />
          </ListItemButton>
          <ListItemButton
            className={classNames(
              "!p-1 !border-r-4 !border-solid !border-blue-500 !border-opacity-0 !px-2.5",
              window.location.pathname === "/workspace/settings"
                ? "!border-opacity-100"
                : ""
            )}
            onClick={() => navigate("/workspace/settings")}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>

          <Text className="p-3 whitespace-nowrap">Workspace Views</Text>
          <span className="flex justify-between px-3 pb-2">
            <Text className="whitespace-nowrap">Your Boards</Text>
            <CreateBoard from="Sidebar" />
          </span>
          {currentWorkspace?.map((boardName, index) => {
            return (
              <ListItemButton
                key={index}
                className={classNames(
                  "!p-1 !border-r-4 !border-solid !border-blue-500 !border-opacity-0 !px-2.5",
                  window.location.pathname === `/workspace/boards/${boardName}`
                    ? "!border-opacity-100"
                    : ""
                )}
                onClick={() => navigate(`/workspace/boards/${boardName}`)}
              >
                <ListItemIcon>
                  <Avatar
                    className="!h-6 !w-6 !text-base !capitalize !font-semibold !rounded"
                    alt={boardName}
                    src="broken.jpg"
                  />
                </ListItemIcon>
                <ListItemText
                  className="!whitespace-nowrap !capitalize"
                  primary={boardName.replace("-", " ")}
                />
              </ListItemButton>
            );
          })}
        </>
      )}
    </List>
  );
};
export default SidebarNew;
