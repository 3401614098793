import * as React from "react";
import Menu from "@mui/material/Menu";
import Text from "../../../Shared/Text";
import {
  ExpandMore,
  KeyboardArrowDown,
  Star,
  StarBorder,
} from "@mui/icons-material";
import { Avatar, Divider, IconButton, MenuItem } from "@mui/material";
import CustomButton from "../../CustomButton";

const RecentMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [stared, setStared] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        variant="text"
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        className="hover:!bg-white focus:!bg-white focus:!bg-opacity-80 hover:!bg-opacity-20 !text-sm !capitalize !mx-1 !text-black"
      >
        Recent
      </CustomButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-2"
      >
        <Text className="text-center p-1">Starred Items</Text>
        <Divider />
        {[
          { id: 1, name: "Default Workspace" },
          { id: 2, name: "New Workspace" },
        ].map((item) => {
          return (
            <MenuItem
              key={item.id}
              className="p-4 flex !justify-between gap-2 w-72"
            >
              <span className="flex gap-2">
                <Avatar
                  src="broken.jpg"
                  alt="New"
                  className="!rounded !bg-orange-600"
                />
                <span>
                  <Text className="font-semibold">{item.name}</Text>
                  <Text className="text-xs">{item.name}</Text>
                </span>
              </span>
              <IconButton
                size="small"
                onClick={() => setStared(!stared)}
                className=""
              >
                {stared ? (
                  <Star className="!text-yellow-500" />
                ) : (
                  <StarBorder className="hover:!text-yellow-500" />
                )}
              </IconButton>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default RecentMenu;
