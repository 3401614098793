import { Search } from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import BoardHeader from "../../Components/Bords/BoardHeader";
import CustomDiv from "../../Shared/CustomDiv";
import Text from "../../Shared/Text";

const BoardsManagement = () => {
  const [value, setValue] = React.useState();
  const navigate = useNavigate();

  const currentWorkspace =
    localStorage.getItem("current-workspace") === "default-workspace"
      ? ["default-board", "new-board", "testing-board", "project-board"]
      : ["aara-board", "cloud-board", "erp-board"];
  return (
    <>
      <BoardHeader />
      <CustomDiv className="px-5">
        <CustomDiv className="py-3">
          <Text className="text-2xl font-bold text-black text-opacity-80">
            Boards
          </Text>

          <CustomDiv className="py-2 flex justify-between items-center">
            <span className="flex gap-3 items-center">
              <span className="">
                <Text className="text-sm m-1">Sort By</Text>
                <FormControl fullWidth size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    defaultValue="1"
                    size="small"
                    className="w-60"
                    onChange={(event) => setValue(event.target.value)}
                  >
                    <MenuItem value={1}>Most Recently Active</MenuItem>
                    <MenuItem value={2}>Last Recently Active</MenuItem>
                    <MenuItem value={3}>Alphabetically A to Z</MenuItem>
                    <MenuItem value={4}>Alphabetically Z to A</MenuItem>
                  </Select>
                </FormControl>
              </span>
              <span className="">
                <Text className="text-sm m-1">Filter By</Text>
                <FormControl fullWidth size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    defaultValue="1"
                    size="small"
                    className="w-60"
                    onChange={(event) => setValue(event.target.value)}
                  >
                    <MenuItem value={1}>Choose A Collection</MenuItem>
                  </Select>
                </FormControl>
              </span>
            </span>
            <span className="">
              <Text className="text-sm m-1">Search</Text>
              <TextField
                InputProps={{
                  startAdornment: (
                    <Search className="!m-0 !p-0 !text-gray-600" />
                  ),
                }}
                placeholder="Search Boards.."
                size="small"
                className="!pl-2"
              />
            </span>
          </CustomDiv>
          <Grid container columns={5}>
            <CustomDiv className="bg-white mr-4 my-2 bg-opacity-25 hover:bg-opacity-50 cursor-pointer transition-all duration-300 h-40 w-1/5 rounded flex justify-center items-center">
              <Text className="">Create New Board</Text>
            </CustomDiv>
            {currentWorkspace?.map((boardName) => {
              return (
                <CustomDiv
                  key={boardName}
                  style={{
                    backgroundImage: `url("https://cdn.pixabay.com/photo/2016/05/05/02/37/sunset-1373171__480.jpg")`,
                  }}
                  onClick={() => navigate(`/workspace/boards/${boardName}`)}
                  className="bg-cover mr-4 my-2 bg-no-repeat cursor-pointer w-1/5 transition-all duration-300 h-40 rounded flex p-2 text-lg font-semibold"
                >
                  <Text className="text-white capitalize shadow">
                    {boardName.replace("-", " ")}
                  </Text>
                </CustomDiv>
              );
            })}
          </Grid>
          <Button
            variant="contained"
            size="small"
            disableElevation
            className="!capitalize"
          >
            View closed boards
          </Button>
        </CustomDiv>
      </CustomDiv>
    </>
  );
};

export default BoardsManagement;
