import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { ListItemIcon, TextField } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { Add, People } from "@mui/icons-material";
import Text from "../../../Shared/Text";
import CustomDiv from "../../../Shared/CustomDiv";
import { Link } from "react-router-dom";

const InviteMembers = ({ from }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {from === "MainHeader" ? (
        <Button
          onClick={handleOpen}
          variant="contained"
          size="small"
          disableElevation
          className="!capitalize"
          startIcon={<People />}
        >
          Invite Workspace Members
        </Button>
      ) : (
        <ListItemIcon
          className="hover:!bg-white !min-w-0 hover:!bg-opacity-30"
          onClick={handleOpen}
        >
          <Add />
        </ListItemIcon>
      )}

      <Modal open={open} onClose={handleClose} className="absolute">
        <Box className="absolute top-1/2 left-1/2 p-4 rounded-sm !outline-none -translate-x-1/2 bg-white -translate-y-1/2 w-1/2">
          <Text className="text-2xl">Invite to Workspace</Text>
          <TextField
            placeholder="Email Address or Name"
            size="small"
            className="w-full !mt-3"
          />
          <CustomDiv className="my-2 flex gap-2 items-center">
            <span className="bg-gray-200 p-2 rounded">
              <LinkIcon />
            </span>
            <span>
              <Text>Invite someone to this Workspace with a link</Text>
              <Link to="#" className="text-sm underline">
                Create Link
              </Link>
            </span>
          </CustomDiv>
        </Box>
      </Modal>
    </>
  );
};
export default InviteMembers;
