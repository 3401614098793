import * as React from "react";
import Menu from "@mui/material/Menu";
import Text from "../../../Shared/Text";
import CustomDiv from "../../../Shared/CustomDiv";
import { OpenInNew } from "@mui/icons-material";
import { Avatar, Divider, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    navigate("/");
  };

  return (
    <>
      <Avatar
        src="broken.jpg"
        alt="MS"
        onClick={handleClick}
        className="!h-8 !w-8 cursor-pointer !text-base !bg-orange-700"
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-2"
      >
        <Text className="mx-3 text-xs mt-2 font-semibold">ACCOUNTS</Text>
        <CustomDiv className="p-4 flex flex-col gap-2">
          <span className="flex items-center gap-2 cursor-pointer">
            <Avatar src="broken.jpg" alt="MS" className=" !bg-orange-600" />

            <span>
              <Text className="font-semibold">Mani Kant Sharma</Text>
              <Text className="text-xs">dadzheromani@gmail.com</Text>
            </span>
          </span>
        </CustomDiv>
        <MenuItem>Switch Accounts</MenuItem>
        <MenuItem>
          <span className="flex justify-between w-full">
            <Text>Manage Accounts</Text> <OpenInNew />
          </span>
        </MenuItem>
        <Divider />
        <Text className="mx-3 mb-2 font-semibold text-xs">WORKSPACE</Text>
        <MenuItem>Profile and Visibility</MenuItem>
        <MenuItem>Activity</MenuItem>
        <MenuItem>Cards</MenuItem>
        <MenuItem>Settings</MenuItem>
        <MenuItem>Helps</MenuItem>
        <MenuItem>Shortcuts</MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>Log out</MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
