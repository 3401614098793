import * as React from "react";
import Menu from "@mui/material/Menu";
import { Close, MoreVert, OpenInNew } from "@mui/icons-material";
import {
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomDiv from "../../../CustomDiv";
import Text from "../../../Text";

const OptionMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-1 shadow"
      >
        <CustomDiv className="flex flex-col w-64">
          <span className="flex justify-between items-center px-1">
            <Text></Text>
            <Text className="text-center p-1">Notifications Settings</Text>
            <IconButton size="small " className="" onClick={handleClose}>
              <Close />
            </IconButton>
          </span>
          <Divider />
          <CustomDiv className="flex flex-col p-2">
            <FormControl fullWidth size="small">
              <Select
                value={value}
                size="small"
                defaultValue={2}
                className="w-full"
                onChange={(event) => setValue(event.target.value)}
              >
                <MenuItem value={1}>Never</MenuItem>
                <MenuItem value={2}>Periodically</MenuItem>
                <MenuItem value={3}>Instantly</MenuItem>
              </Select>
            </FormControl>
          </CustomDiv>
          <MenuItem>Allow desktop notifications</MenuItem>
          <MenuItem className="flex !justify-between">
            <span>All notification settings</span> <OpenInNew />
          </MenuItem>
        </CustomDiv>
      </Menu>
    </>
  );
};

export default OptionMenu;
