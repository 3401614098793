import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ClockCircleOutlined } from "@ant-design/icons";
import {
  Avatar,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  Select,
} from "@mui/material";
import Text from "../../../Shared/Text";
import CustomInput from "../../../Shared/CustomInput";
import CustomDiv from "../../../Shared/CustomDiv";
import { CalendarMonth, Circle, Edit, Sort } from "@mui/icons-material";
import CustomButton from "../../../Shared/CustomButton";

const LabelsDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        onClick={handleClick}
        className="!bg-slate-200 !text-black hover:!bg-slate-300 w-full text-start"
      >
        Labels
      </CustomButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-1"
      >
        <Text className="text-center my-1">Labels</Text>
        <Divider />
        <CustomDiv className="p-4 flex flex-col w-80">
          <span>
            <CustomInput label="Search labels" className="w-full" />
          </span>

          <Text className="text-xs font-semibold m-1">Labels</Text>
          <span className="flex items-center cursor-pointer ">
            <Checkbox />
            <span className="bg-green-200 w-full p-1">
              <Circle className="!text-green-500 m-1 !text-lg" />
            </span>
            <IconButton size="small">
              <Edit />
            </IconButton>
          </span>
          <span className="flex items-center cursor-pointer ">
            <Checkbox />
            <span className="bg-yellow-200 w-full p-1">
              <Circle className="!text-yellow-500 m-1 !text-lg" />
            </span>
            <IconButton size="small">
              <Edit />
            </IconButton>
          </span>
          <span className="flex items-center cursor-pointer ">
            <Checkbox />
            <span className="bg-blue-200 w-full p-1">
              <Circle className="!text-blue-500 m-1 !text-lg" />
            </span>
            <IconButton size="small">
              <Edit />
            </IconButton>
          </span>
          <span className="flex items-center cursor-pointer ">
            <Checkbox />
            <span className="bg-pink-200 w-full p-1">
              <Circle className="!text-pink-500 m-1 !text-lg" />
            </span>
            <IconButton size="small">
              <Edit />
            </IconButton>
          </span>
          <span className="flex items-center cursor-pointer ">
            <Checkbox />
            <span className="bg-red-200 w-full p-1">
              <Circle className="!text-red-500 m-1 !text-lg" />
            </span>
            <IconButton size="small">
              <Edit />
            </IconButton>
          </span>
          <span className="flex items-center cursor-pointer ">
            <Checkbox />
            <span className="bg-purple-200 w-full p-1">
              <Circle className="!text-purple-500 m-1 !text-lg" />
            </span>
            <IconButton size="small">
              <Edit />
            </IconButton>
          </span>
          <CustomButton className="!bg-slate-100 !text-black hover:!bg-slate-200 !my-2 w-full text-start">
            Create a new label
          </CustomButton>
          <Divider />

          <CustomButton className="!bg-slate-100 !text-black hover:!bg-slate-200 !mt-2 w-full text-start">
            Give us feedback
          </CustomButton>
        </CustomDiv>
      </Menu>
    </>
  );
};

export default LabelsDropdown;
