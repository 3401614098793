import React, { useState } from "react";
import {
  Divider,
  FormControl,
  InputLabel,
  ListItemIcon,
  Select,
  Menu,
  MenuItem,
} from "@mui/material";
import { Add, Assessment } from "@mui/icons-material";
import CustomDiv from "../../../CustomDiv";
import Text from "../../../Text";
import CustomInput from "../../../CustomInput";
import CustomButton from "../../../CustomButton";
import classNames from "classnames";

const CreateBoard = ({ from }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {from === "MainHeader" ? (
        <CustomDiv
          onClick={handleClick}
          className="flex flex-col !text-start w-80 p-2 !px-3 hover:bg-slate-100 focus:bg-slate-200 cursor-pointer "
        >
          <span className="flex !text-start font-bold">
            <Assessment className="mr-1" /> Create Board
          </span>
          <Text className="text-sm">
            A board is made up of cards ordered on lists. Use it to manage
            projects, track information, or organize anything.
          </Text>
        </CustomDiv>
      ) : (
        <ListItemIcon
          className="hover:!bg-white !min-w-0 hover:!bg-opacity-30"
          onClick={handleClick}
        >
          <Add />
        </ListItemIcon>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={
          from === "Sidebar"
            ? {
                vertical: "bottom",
                horizontal: "right",
              }
            : {
                vertical: "bottom",
                horizontal: "bottom",
              }
        }
        onClose={handleClose}
        className={classNames(
          "!-top-[110px]",
          from === "Sidebar" ? "!left-[18px]" : ""
        )}
      >
        <Text className="text-center my-1">Create Boards</Text>
        <Divider />
        <CustomDiv className="p-2 flex flex-col gap-5 w-80">
          <CustomInput label="Board Title" className="w-full" />

          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Workspace</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              size="small"
              label="Workspace"
              onChange={(event) => setValue(event.target.value)}
            >
              <MenuItem value={1}>Project Manager</MenuItem>
              <MenuItem value={2}>Trello Workspace</MenuItem>
              <MenuItem value={3}>Default Workspace</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Visibility</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              size="small"
              label="Visibility"
              onChange={(event) => setValue(event.target.value)}
            >
              <MenuItem value={1}>Private</MenuItem>
              <MenuItem value={2}>Workspace</MenuItem>
              <MenuItem value={3}>Public</MenuItem>
            </Select>
          </FormControl>

          <CustomButton
            variant="contained"
            size="small"
            className="!capitalize"
          >
            Create
          </CustomButton>
          <CustomButton
            variant="contained"
            size="small"
            className="!capitalize !bg-gray-400"
            onClick={handleClose}
          >
            Cancel
          </CustomButton>
          <Text className="px-2 text-sm">
            By using images from Unsplash, you agree to their
            <span className="underline px-1">license</span> and
            <span className="underline px-1">Terms of Service</span> .
          </Text>
        </CustomDiv>
      </Menu>
    </>
  );
};

export default CreateBoard;
