import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { Divider, FormControl, InputLabel, Select } from "@mui/material";
import CustomButton from "../../../../Shared/CustomButton";
import Text from "../../../../Shared/Text";
import CustomDiv from "../../../../Shared/CustomDiv";
import CustomInput from "../../../../Shared/CustomInput";

const Share = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        onClick={handleClick}
        className="!bg-slate-200 !text-black hover:!bg-slate-300 w-full text-start"
      >
        Share
      </CustomButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-1"
      >
        <Text className="text-center my-1">Share and more…</Text>
        <Divider />
        <MenuItem>Print... </MenuItem>
        <MenuItem>Export JSON </MenuItem>
        <Divider />
        <CustomDiv className="flex flex-col gap-1 w-80 px-2 py-1">
          <Text className="text-sm font-semibold">Link to this card </Text>
          <CustomInput
            label=""
            value="https://trello.com/c/MipARDcF"
            className="w-full"
          />
          <Text className="hover:underline w-fit cursor-pointer">
            Show QR Code
          </Text>
          <Text className="text-sm font-semibold">Embed this card </Text>
          <CustomInput
            label=""
            value="https://trello.com/c/MipARDcF"
            className="w-full"
          />
          <Text className="text-sm font-semibold">Email for this card</Text>
          <CustomInput
            label=""
            value="mkxreactjsdev@gmail.com"
            className="w-full"
          />

          <Text className="text-sm font-semibold">
            Emails sent to this address will appear as a comment by you on the
            card
          </Text>
          <Divider />
          <span className="flex items-center gap-1">
            <Text className="font-semibold"> Card #7</Text>
            <Text>Added 22 minutes ago </Text>
            {" . "}
            <Text className="underline cursor-pointer text-gray-500 hover:text-gray-700">
              Delete
            </Text>
          </span>
        </CustomDiv>
      </Menu>
    </>
  );
};

export default Share;
