import {
  PlusOutlined,
  ShareAltOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  ArchiveOutlined,
  Circle,
  ListSharp,
  Monitor,
  Subject,
  Visibility,
} from "@mui/icons-material";
import { Avatar, Box, Modal } from "@mui/material";
import CustomButton from "../../../Shared/CustomButton";
import CustomDiv from "../../../Shared/CustomDiv";
import CustomInput from "../../../Shared/CustomInput";
import Text from "../../../Shared/Text";
import React from "react";
import Attachments from "../Attachments";
import Checklist from "../Checklist";
import Copy from "../Copy";
import Dates from "../Dates";
import Members from "../MembersDropdwon";
import Move from "../Move";
import LabelsDropdown from "../LabelsDropdown";
import EmojiPickers from "../../../Shared/EmojiPicker";
import Share from "./ShareMenu";
const CardDetailModal = ({ setIsModalOpen, isModalOpen, cardData }) => {
  const handleClose = () => setIsModalOpen(false);
  return (
    <>
      <Modal open={isModalOpen} onClose={handleClose}>
        <Box className="absolute top-1/2 left-1/2 !outline-none flex -translate-x-1/2 bg-white p-4 -translate-y-1/2 h-5/6 w-3/5 overflow-y-auto">
          <CustomDiv className="w-3/4 flex flex-col">
            <span className="flex gap-1 items-center">
              <Monitor />
              <Text className="text-xl capitalize">{cardData}</Text>
            </span>
            <Text className="ml-7 text-xs">in Main Card</Text>
            <span className="m-5 w-full">
              <Text className="text-xs font-semibold mt-1">Notfications</Text>
              <CustomButton
                startIcon={<Visibility />}
                className="!bg-slate-200 !text-black hover:!bg-slate-300 !mx-3 !my-1"
              >
                Watch
              </CustomButton>
            </span>

            <span className="flex gap-2 mt-4 w-full">
              <Subject />
              <span className="flex w-full flex-col">
                <Text className="text-xl">Description</Text>
                <CustomInput
                  multiline={true}
                  rows={3}
                  placeholder="Add a more detailed description.."
                  className="!w-full !my-2"
                />
              </span>
            </span>
            <span className="flex gap-2 my-2 w-full">
              <span className="flex justify-between items-center w-full">
                <span className="flex items-center gap-2">
                  <ListSharp />
                  <Text className="text-xl">Activity</Text>
                </span>
                <CustomButton variant="text" className="bg-slate-200">
                  Show Details
                </CustomButton>
              </span>
            </span>
            <span className="flex items-center !my-2 gap-2 w-full">
              <Avatar
                className="!h-8 !w-8 !bg-orange-700"
                src="broken.jpg"
                alt="M"
              />
              <CustomInput
                className="w-full"
                placeholder="Write a comment..."
              />
            </span>
            <span className="flex items-start !my-2 gap-2 w-full">
              <Avatar
                className="!h-8 !w-8 !bg-orange-700"
                src="broken.jpg"
                alt="M"
              />
              <span className="flex flex-col w-full">
                <span className="flex items-center gap-2">
                  <Text>Mani Kant Sharma</Text>
                  <Text className="text-xs">2 minutes ago</Text>
                </span>

                <Text className="shadow p-2 m-1 bg-slate-100 w-full border">
                  Comment
                </Text>
                <span className="flex items-center gap-2">
                  <EmojiPickers />
                  {" . "}
                  <Text className="underline cursor-pointer text-gray-500 hover:text-gray-700">
                    Edit
                  </Text>
                  {" . "}
                  <Text className="underline cursor-pointer text-gray-500 hover:text-gray-700">
                    Delete
                  </Text>
                </span>
              </span>
            </span>
          </CustomDiv>

          <CustomDiv className="flex w-1/4 flex-col gap-3 p-4">
            <Text className="text-sm font-semibold">Add to card</Text>
            <Members />
            <LabelsDropdown />
            <Checklist />
            <Dates />
            <Attachments />
            <CustomButton
              icon={<UserOutlined />}
              className="!bg-slate-200 !text-black hover:!bg-slate-300 w-full"
            >
              Cover
            </CustomButton>
            <CustomButton
              icon={<UserOutlined />}
              disabled={true}
              className="!bg-slate-200 !text-black hover:!bg-slate-300 "
            >
              Custom Fields
            </CustomButton>
            <Text className="text-sm font-semibold">Power-Ups</Text>
            <CustomButton
              icon={<PlusOutlined />}
              className="!bg-slate-200 !text-black hover:!bg-slate-300 w-full"
            >
              Power Ups
            </CustomButton>
            <Text className="text-sm font-semibold">Automation</Text>
            <CustomButton
              icon={<PlusOutlined />}
              className="!bg-slate-200 !text-black hover:!bg-slate-300 w-full"
            >
              Add Button
            </CustomButton>
            <Text className="text-sm font-semibold">Actions</Text>
            <Move />
            <Copy />
            <CustomButton
              icon={<PlusOutlined />}
              className="!bg-slate-200 !text-black hover:!bg-slate-300 w-full"
            >
              Make Templates
            </CustomButton>
            <CustomButton
              icon={<ArchiveOutlined className="!text-base mr-2" />}
              className="!bg-slate-200 !text-black hover:!bg-slate-300 w-full"
            >
              Archive
            </CustomButton>
            <Share />
          </CustomDiv>
        </Box>
      </Modal>
    </>
  );
};
export default CardDetailModal;
