import React from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../../Shared/CustomButton";
import CustomDiv from "../../Shared/CustomDiv";
import CustomInput from "../../Shared/CustomInput";
import Text from "../../Shared/Text";
import signinbg from "../../Assets/Others/signin.svg";
import {
  Checkbox,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { useFormik } from "formik";

const SignIn = () => {
  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
  });
  return (
    <CustomDiv
      className="flex items-center justify-center bg-cover bg-no-repeat h-screen"
      style={{
        backgroundImage: `url("https://images.unsplash.com/photo-1481007553706-bde1ba8e91fd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80")`,
      }}
    >
      <div className="flex !rounded bg-white bg-opacity-10 backdrop-blur w-1/2 h-2/3 border border-white border-opacity-10">
        <img src={signinbg} alt="" className="rounded-l scale-90" />
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col justify-center w-full rounded-r items-center mr-10"
        >
          <Text className="text-2xl my-5 !text-white font-semibold">
            Sign In
          </Text>

          <ThemeProvider theme={theme}>
            <span className="flex flex-col w-full gap-4">
              <CustomInput
                label="Email"
                id="email"
                name="email"
                className="w-full"
                onChange={formik.handleChange}
                value={formik.values["email"]}
              />
              <CustomInput
                label="Password"
                className="w-full"
                type="password"
                id="password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values["password"]}
              />
            </span>

            <CustomDiv className="flex justify-between w-full items-center">
              <span className="flex items-center my-px">
                <Checkbox className="!p-0" size="small"/>
                <Text className="text-sm !text-white mx-px">Remember Me</Text>
              </span>
              <Link className="text-sm mx-px" to="#">
                <Text className="!text-white"> Forget Credentials?</Text>
              </Link>
            </CustomDiv>
          </ThemeProvider>
          <CustomButton
            className="!mt-5"
            type="submit"
            onClick={() => navigate("/workspace/boards-management")}
          >
            Sign In
          </CustomButton>
        </form>
      </div>
    </CustomDiv>
  );
};

export default SignIn;
