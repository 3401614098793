import { Button } from "@mui/material";
import classNames from "classnames";
import React from "react";

const CustomButton = ({
  children,
  onClick,
  className = "",
  endIcon,
  startIcon,
  type = "",
  variant = "contained",
  disabled = false,
}) => {
  return (
    <Button
      disableElevation
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      size="small"
      type={type}
      className={classNames("!capitalize", className)}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
