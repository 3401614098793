import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Avatar,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { Close, People, PersonAdd } from "@mui/icons-material";
import Text from "../../../Shared/Text";
import CustomDiv from "../../../Shared/CustomDiv";
import { Link } from "react-router-dom";
import CustomButton from "../../../Shared/CustomButton";
import CustomInput from "../../CustomInput";

const ShareMenuHead = () => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <CustomButton
        onClick={handleOpen}
        startIcon={<PersonAdd />}
        className="!text-sm !px-2 !p-2 !capitalize !text-white"
      >
        Share
      </CustomButton>

      <Modal open={open} onClose={handleClose} className="absolute">
        <Box className="absolute top-1/2 left-1/2 p-4 rounded-sm !outline-none -translate-x-1/2 bg-white -translate-y-1/2 w-1/3">
          <span className="flex justify-between items-center">
            <Text className="text-2xl">Share Board</Text>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </span>

          <CustomDiv className="flex items-center w-full gap-2">
            <CustomInput label="Email address or Name" className="w-full" />
            <FormControl fullWidth size="small">
              <Select
                value={value}
                size="small"
                className="w-fit"
                defaultValue={1}
                onChange={(event) => setValue(event.target.value)}
              >
                <MenuItem value={1}>Member</MenuItem>
                <MenuItem value={2}>Observer</MenuItem>
              </Select>
            </FormControl>
            <CustomButton className="!p-2 !px-4">Share</CustomButton>
          </CustomDiv>
          <CustomDiv className="my-2 flex gap-2 items-center">
            <span className="bg-gray-200 p-2 rounded">
              <LinkIcon />
            </span>
            <span>
              <Text>Share this board with a link</Text>
              <Link to="#" className="text-sm underline">
                Create Link
              </Link>
            </span>
          </CustomDiv>
          <CustomDiv className="flex w-full ">
            <span className="flex items-center justify-between gap-3 w-full ">
              <Avatar
                className="!bg-gradient-to-t !h-8 !w-8 !from-yellow-600 !to-red-600"
                src="broken.jpg"
                alt="Project Manager"
              />
              <span className="flex items-center">
                <span className="w-full">
                  <Text className="whitespace-nowrap !capitalize">
                    Mani Kant Sharma
                  </Text>
                  <Text className="whitespace-nowrap text-xs">
                    mkxreactjsdev@gmail.com {" . "} Workspace admin
                  </Text>
                </span>
              </span>
              <FormControl fullWidth size="small">
                <Select
                  value={value}
                  size="small"
                  className="w-fit"
                  defaultValue={1}
                  onChange={(event) => setValue(event.target.value)}
                >
                  <MenuItem value={1}>Admin</MenuItem>
                  <MenuItem value={2}>Observer</MenuItem>
                  <MenuItem value={3}>Member</MenuItem>
                  <MenuItem value={4}>Leave Board</MenuItem>
                </Select>
              </FormControl>
            </span>
          </CustomDiv>
        </Box>
      </Modal>
    </>
  );
};
export default ShareMenuHead;
