import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomButton from "../../../Shared/CustomButton";
import { ClockCircleOutlined } from "@ant-design/icons";
import {
  Divider,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import Text from "../../../Shared/Text";
import CustomDiv from "../../../Shared/CustomDiv";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Dates = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        icon={<ClockCircleOutlined />}
        type="info"
        onClick={handleClick}
        className="!bg-slate-200 !text-black hover:!bg-slate-300 w-full text-start"
      >
        Dates
      </CustomButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-1"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Text className="text-center my-1">Dates</Text>
          <Divider />
          <CustomDiv className="p-3 mt-3 flex flex-col gap-3">
            <DateTimePicker
              renderInput={(props) => <TextField {...props} size="small" />}
              label="Start Date"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
            />
            <DateTimePicker
              renderInput={(props) => <TextField {...props} size="small" />}
              label="Due Date"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
            />
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                Set due date reminder
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                size="small"
                label="Set due date reminder"
                onChange={(event) => setValue(event.target.value)}
              >
                <MenuItem value={1}>At Time Of Due Date</MenuItem>
                <MenuItem value={2}>5 Min Before</MenuItem>
                <MenuItem value={3}>10 Min Before</MenuItem>
                <MenuItem value={4}>15 Min Before</MenuItem>
                <MenuItem value={5}>1 Hour Before</MenuItem>
                <MenuItem value={6}>2 Hour Before</MenuItem>
                <MenuItem value={7}>1 Day Before</MenuItem>
                <MenuItem value={8}>2 Days Before</MenuItem>
              </Select>
            </FormControl>
            <Text>
              Reminders will be sent to all the members <br /> and the watchers
              of this card
            </Text>
            <CustomButton onClick={handleClose}>Save</CustomButton>
            <CustomButton
              onClick={handleClose}
              type="info"
              className="!bg-slate-200 hover:!bg-slate-300"
            >
              Cancel
            </CustomButton>
          </CustomDiv>
        </LocalizationProvider>
      </Menu>
    </>
  );
};

export default Dates;
