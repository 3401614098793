import React, { useState } from "react";
import { UsergroupAddOutlined } from "@ant-design/icons";
import CustomDiv from "../CustomDiv";
import Text from "../Text";
import { Avatar, Divider, Drawer, IconButton } from "@mui/material";
import {
  Bolt,
  ExpandMore,
  Power,
  MoreHoriz,
  Leaderboard,
  StarBorder,
  Star,
  PersonAdd,
} from "@mui/icons-material";
import FilterMenu from "../../Components/Bords/FilterMenu";
import CustomButton from "../CustomButton";
import WorkspaceVisibleMenu from "./WorkdpaceVisibleMenu";
import ShareMenuHead from "./ShareMenuHead";
import ProfileMenuHead from "./ProfileMenuHead";

const Head = ({ boardName }) => {
  const [open, setOpen] = useState(false);
  const [stared, setStared] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <CustomDiv className=" px-4 !bg-transparent gap-3 h-14 items-center justify-between flex">
      <span className="flex">
        <Text className="text-lg font-semibold !capitalize">
          {boardName.replace("-", " ")}
        </Text>
        <IconButton
          size="small"
          onClick={() => setStared(!stared)}
          className="!rounded !mx-2 !bg-white cursor-pointer backdrop-blur !bg-opacity-30 flex items-center hover:!bg-opacity-40"
        >
          {stared ? (
            <Star className="!text-yellow-500" />
          ) : (
            <StarBorder className="hover:!text-yellow-500" />
          )}
        </IconButton>
        <WorkspaceVisibleMenu />
        <CustomButton
          variant="text"
          startIcon={<Leaderboard />}
          className="!bg-white !bg-opacity-30 !px-2 !text-sm !capitalize !mx-1 !text-black"
        >
          Board
        </CustomButton>
        <span className="!rounded !bg-white cursor-pointer mx-2 backdrop-blur !bg-opacity-30 p-1 flex items-center hover:!bg-opacity-40">
          <ExpandMore />
        </span>
      </span>
      <span className="flex gap-2">
        <CustomButton
          variant="text"
          startIcon={<Power />}
          className="!bg-white !bg-opacity-30 !px-2 !text-sm !capitalize !text-black"
        >
          Power Ups
        </CustomButton>
        <CustomButton
          variant="text"
          startIcon={<Bolt />}
          className="!bg-white !bg-opacity-30 !px-2 !text-sm !capitalize !mr-1 !text-black"
        >
          Automation
        </CustomButton>
        <FilterMenu />

        <ProfileMenuHead />
        <ShareMenuHead />
        <span
          onClick={showDrawer}
          className="!rounded !bg-white cursor-pointer mx-1 backdrop-blur !bg-opacity-30 p-1 flex items-center hover:!bg-opacity-40"
        >
          <MoreHoriz />
        </span>

        <Drawer anchor="right" onClose={onClose} open={open}>
          <Text className="text-center p-1 font-semibold">Recent Activity</Text>
          <Divider />

          <CustomDiv className="w-96 p-1">
            <Text>Content </Text>
            <Text>Content </Text>
            <Text>Content </Text>
            <Text>Content </Text>
            <Text>Content </Text>
            <Text>Content </Text>
          </CustomDiv>
        </Drawer>
      </span>
    </CustomDiv>
  );
};

export default Head;
