import { Add, Close, Edit, MoreHoriz } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../Shared/CustomButton";
import CustomDiv from "../../Shared/CustomDiv";
import CustomInput from "../../Shared/CustomInput";
import Text from "../../Shared/Text";
import { useSnackbar } from "notistack";
import CardDetailModal from "../../Components/Bords/CardDetailModal";
import { useParams } from "react-router-dom";
import Head from "../../Shared/Head";

const Bords = () => {
  const [card, setCard] = useState([]);
  const [textField, setTextField] = useState(false);
  const [textFieldMain, setTextFieldMain] = useState(false);
  const [cardName, setCardName] = useState();
  const [mainCardName, setMainCardName] = useState();
  const [cardList, setCardList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cardData, setCardData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { boardName } = useParams();

  const handleTextField = () => {
    setTextField(true);
  };

  const handleChange = (event) => {
    setCardName(event.target.value);
  };

  const handleChangeMain = (event) => {
    setMainCardName(event.target.value);
  };
  const handleAddCard = () => {
    setCard((prevData) => [...prevData, { id: Math.random, name: cardName }]);
    setTextField(false);
    setCardName();
  };
  const handleAddCardInList = () => {
    setCardList((prev) => [
      ...prev,
      {
        id: Math.random,
        card_name: mainCardName,
      },
    ]);
    setMainCardName();
    setTextFieldMain(false);
  };
  const handleCardClick = (name) => {
    setIsModalOpen(true);
    setCardData(name);
  };

  return (
    <>
      <Head boardName={boardName} />
      <CustomDiv className="p-2">
        <CustomDiv className="grid grid-cols-5 gap-3">
          <CustomDiv className="bg-slate-200 p-2 rounded flex flex-col gap-1">
            <CustomDiv className="flex justify-between items-center">
              <Text className="whitespace-nowrap">Card Name</Text>
              <IconButton>
                <MoreHoriz />
              </IconButton>
            </CustomDiv>
            {card.map((input) => {
              return (
                <CustomDiv
                  key={input.name}
                  className="flex justify-between p-1 !px-2  items-center bg-white rounded"
                  onClick={() => handleCardClick(input.name)}
                >
                  <Text>{input.name}</Text>
                  <IconButton className="!p-1 !rounded hover:!bg-slate-100">
                    <Edit className="!text-slate-300" />
                  </IconButton>
                </CustomDiv>
              );
            })}

            {textField ? (
              <CustomDiv className="flex flex-col h-fit gap-2">
                <CustomInput
                  placeholder="Add a title for this card.."
                  onChange={handleChange}
                />
                <span className="flex justify-between items-center">
                  <span className="flex gap-2 items-center">
                    <CustomButton
                      onClick={() =>
                        cardName
                          ? handleAddCard()
                          : enqueueSnackbar("You forget to enter a title..!", {
                              variant: "info",
                            })
                      }
                    >
                      Add Card
                    </CustomButton>
                    <IconButton onClick={() => setTextField(false)}>
                      <Close />
                    </IconButton>
                  </span>
                  <IconButton>
                    <MoreHoriz />
                  </IconButton>
                </span>
              </CustomDiv>
            ) : (
              <CustomDiv className="flex h-fit items-center justify-between">
                <button
                  className="!capitalize w-full rounded hover:!bg-slate-400 hover:!bg-opacity-20 p-1 text-start"
                  onClick={handleTextField}
                >
                  <Add /> Add a card
                </button>
              </CustomDiv>
            )}
          </CustomDiv>
          {cardList.map((cardinfo, index) => {
            return (
              <CustomDiv
                key={index}
                className="bg-slate-200 h-fit p-2 rounded flex flex-col gap-1"
              >
                <CustomDiv className="flex justify-between items-center">
                  <Text className="whitespace-nowrap">
                    {cardinfo.card_name}
                  </Text>
                  <IconButton>
                    <MoreHoriz />
                  </IconButton>
                </CustomDiv>
                {[].map((input) => {
                  return (
                    <CustomDiv className="flex justify-between p-1 !px-2 items-center bg-white rounded">
                      <Text>{input.name}</Text>
                      <IconButton className="!p-1 !rounded hover:!bg-slate-100">
                        <Edit className="!text-slate-300" />
                      </IconButton>
                    </CustomDiv>
                  );
                })}

                {textField === "name" ? (
                  <CustomDiv className="flex flex-col h-fit gap-2">
                    <CustomInput
                      placeholder="Add a title for this card.."
                      onChange={handleChange}
                    />
                    <span className="flex justify-between items-center">
                      <span className="flex gap-2 items-center">
                        <CustomButton
                          onClick={() =>
                            cardName
                              ? handleAddCard()
                              : enqueueSnackbar(
                                  "You forget to enter a title..!",
                                  {
                                    variant: "info",
                                  }
                                )
                          }
                        >
                          Add Card
                        </CustomButton>
                        <IconButton onClick={() => setTextField(false)}>
                          <Close />
                        </IconButton>
                      </span>
                      <IconButton>
                        <MoreHoriz />
                      </IconButton>
                    </span>
                  </CustomDiv>
                ) : (
                  <CustomDiv className="flex items-center justify-between">
                    <button
                      className="!capitalize w-full rounded hover:!bg-slate-400 hover:!bg-opacity-20 p-1 text-start"
                      onClick={handleTextField}
                    >
                      <Add /> Add a card
                    </button>
                    <button className="!capitalize hover:!bg-slate-400 hover:!bg-opacity-20 p-1 rounded">
                      <Add />
                    </button>
                  </CustomDiv>
                )}
              </CustomDiv>
            );
          })}
          {textFieldMain ? (
            <CustomDiv className="flex flex-col gap-2 h-fit bg-white rounded p-2">
              <CustomInput
                placeholder="Add a title for this card.."
                onChange={handleChangeMain}
              />
              <span className="flex justify-between items-center">
                <span className="flex gap-2 items-center">
                  <CustomButton
                    onClick={() =>
                      mainCardName
                        ? handleAddCardInList()
                        : enqueueSnackbar("You forget to enter a title..!", {
                            variant: "info",
                          })
                    }
                  >
                    Add card
                  </CustomButton>
                  <IconButton onClick={() => setTextFieldMain(false)}>
                    <Close />
                  </IconButton>
                </span>
              </span>
            </CustomDiv>
          ) : (
            <CustomDiv
              className="bg-black bg-opacity-10 hover:!bg-opacity-20 cursor-pointer p-2 h-fit flex items-center rounded "
              onClick={() => setTextFieldMain(true)}
            >
              <Add /> Add another list
            </CustomDiv>
          )}
        </CustomDiv>
      </CustomDiv>
      <CardDetailModal
        cardData={cardData}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default Bords;
