import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Avatar } from "@mui/material";
import { ExpandMore, KeyboardArrowDown } from "@mui/icons-material";
import CustomDiv from "../../../Shared/CustomDiv";
import Text from "../../../Shared/Text";
import { useNavigate } from "react-router-dom";

const WorkspaceListDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const handleWorkspaceClick = (routes) => {
    navigate(routes);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant="text"
        size="small"
        endIcon={<KeyboardArrowDown />}
        className="hover:!bg-white focus:!bg-white focus:!bg-opacity-80 hover:!bg-opacity-20 !capitalize !ml-3 !text-black"
      >
        workspace 
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-1.5"
      >
        <Text className="mx-2 text-sm font-semibold">Current Workspace</Text>
        <CustomDiv className="flex flex-col w-80 pt-2 h-full">
          <MenuItem className="flex gap-2 !capitalize text-center">
            <Avatar
              className="!rounded !capitalize !bg-gradient-to-t !from-yellow-600 !to-red-600"
              src="broken.jpg"
              alt={localStorage.getItem("current-workspace")}
            />
            {localStorage.getItem("current-workspace")?.replace("-", " ")}
          </MenuItem>
        </CustomDiv>
        <span className="flex justify-between items-center">
          <Text className="mx-2 text-sm font-semibold">Your Workspaces</Text>
          <Text className="text-xs mx-2 font-bold">Double Click to Select</Text>
        </span>

        <CustomDiv className="flex flex-col w-80 pt-2 h-full">
          {["new-workspace", "default-workspace"].map(
            (workspaceName, index) => {
              return (
                <MenuItem
                  key={index}
                  className="flex gap-2 !capitalize text-center h-full"
                  onDoubleClick={handleClose}
                  onClick={() =>
                    handleWorkspaceClick(`/workspace/${workspaceName}`)
                  }
                >
                  <Avatar
                    className="!rounded !capitalize !bg-gradient-to-t !from-yellow-600 !to-red-600"
                    src="broken.jpg"
                    alt={workspaceName.replace("-", " ")}
                  />
                  {workspaceName.replace("-", " ")}
                </MenuItem>
              );
            }
          )}
        </CustomDiv>
      </Menu>
    </>
  );
};

export default WorkspaceListDropdown;
