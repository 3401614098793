import * as React from "react";
import Menu from "@mui/material/Menu";
import Text from "../../../Shared/Text";
import CustomDiv from "../../../Shared/CustomDiv";
import {
  MoreHoriz,
  MoreVert,
  Notifications,
  OpenInNew,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Avatar,
  Divider,
  IconButton,
  MenuItem,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import OptionMenu from "./OptionMenu";

const NotificationsMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleChange = (event) => {
    setNotifications(event.currentTarget.checked);
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <Notifications color="action" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-2 shadow"
      >
        <CustomDiv className="flex flex-col">
          <CustomDiv className="flex justify-between items-center px-2">
            <Text className="text-xl font-semibold">Notifications</Text>
            <span className="flex items-center">
              <Text>Only show unread</Text> <Switch onChange={handleChange} />
              <OptionMenu />
            </span>
          </CustomDiv>
          <Divider />
          {!notifications ? (
            <CustomDiv className="flex flex-col w-96 h-96 gap-1 p-1 overflow-y-auto">
              <Alert className="bg-slate-200 w-full rounded-sm">
                <AlertTitle>Workspace</AlertTitle>
                New workspace created
              </Alert>
              <Alert className="bg-slate-200 w-full rounded-sm">
                <AlertTitle>Boards</AlertTitle>
                New baord created
              </Alert>
              <Alert
                className="bg-slate-200 w-full rounded-sm"
                severity="warning"
              >
                <AlertTitle>Reminder</AlertTitle>
                Default workspace is closed succefully
              </Alert>
              <Alert className="bg-slate-200 w-full rounded-sm" severity="info">
                <AlertTitle>Task</AlertTitle>
                This is In-progress
              </Alert>
              <Alert className="bg-slate-200 w-full rounded-sm">
                <AlertTitle>Success</AlertTitle>
                This is a success alert — <strong>check it out!</strong>
              </Alert>
              <Alert className="bg-slate-200 w-full rounded-sm">
                <AlertTitle>Success</AlertTitle>
                This is a success alert — <strong>check it out!</strong>
              </Alert>
              <Alert className="bg-slate-200 w-full rounded-sm">
                <AlertTitle>Success</AlertTitle>
                This is a success alert — <strong>check it out!</strong>
              </Alert>
              <Alert className="bg-slate-200 w-full rounded-sm">
                <AlertTitle>Success</AlertTitle>
                This is a success alert — <strong>check it out!</strong>
              </Alert>
              <Alert className="bg-slate-200 w-full rounded-sm">
                <AlertTitle>Success</AlertTitle>
                This is a success alert — <strong>check it out!</strong>
              </Alert>
              <Alert className="bg-slate-200 w-full rounded-sm">
                <AlertTitle>Success</AlertTitle>
                This is a success alert — <strong>check it out!</strong>
              </Alert>
              <Alert className="bg-slate-200 w-full rounded-sm">
                <AlertTitle>Success</AlertTitle>
                This is a success alert — <strong>check it out!</strong>
              </Alert>
              <Alert className="bg-slate-200 w-full rounded-sm">
                <AlertTitle>Success</AlertTitle>
                This is a success alert — <strong>check it out!</strong>
              </Alert>
              <Alert className="bg-slate-200 w-full rounded-sm">
                <AlertTitle>Success</AlertTitle>
                This is a success alert — <strong>check it out!</strong>
              </Alert>
              <Alert className="bg-slate-200 w-full rounded-sm">
                <AlertTitle>Success</AlertTitle>
                This is a success alert — <strong>check it out!</strong>
              </Alert>
              <Alert className="bg-slate-200 w-full rounded-sm">
                <AlertTitle>Success</AlertTitle>
                This is a success alert — <strong>check it out!</strong>
              </Alert>
              <Text className="text-2xl hidden">No Notifications</Text>
            </CustomDiv>
          ) : (
            <CustomDiv className="flex flex-col items-center justify-center w-96 h-96 gap-1 p-1">
              <Text className="text-2xl">No unread Notifications</Text>
            </CustomDiv>
          )}
        </CustomDiv>
      </Menu>
    </>
  );
};

export default NotificationsMenu;
