import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomButton from "../../../Shared/CustomButton";
import { Divider, FormControl, InputLabel, Select } from "@mui/material";
import Text from "../../../Shared/Text";
import CustomInput from "../../../Shared/CustomInput";
import CustomDiv from "../../../Shared/CustomDiv";

const Copy = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        onClick={handleClick}
        className="!bg-slate-200 !text-black hover:!bg-slate-300 w-full text-start"
      >
        Copy
      </CustomButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-1"
      >
        <Text className="text-center my-1">Copy Card</Text>
        <Divider />

        <CustomDiv className="p-3 flex flex-col gap-2 ">
          <CustomInput label="Title" multiline={true} rows={2} />
          <Text className="my-1">Copy to..</Text>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Board</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              size="small"
              label="Board"
              className="w-60"
              onChange={(event) => setValue(event.target.value)}
            >
              <MenuItem value={1}>Project Management</MenuItem>
            </Select>
          </FormControl>
          <span className="flex w-full gap-3 my-1">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">List</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                size="small"
                label="List"
                onChange={(event) => setValue(event.target.value)}
              >
                <MenuItem value={1}>Card 1</MenuItem>
                <MenuItem value={2}>Card 2</MenuItem>
                <MenuItem value={3}>card 3</MenuItem>
                <MenuItem value={4}>Card 4</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Position</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                size="small"
                label="Position"
                onChange={(event) => setValue(event.target.value)}
              >
                <MenuItem value={1}>1</MenuItem>
              </Select>
            </FormControl>
          </span>
          <CustomButton>Create Card</CustomButton>
          <CustomButton
            onClick={handleClose}
            type="info"
            className="!bg-slate-200 hover:!bg-slate-300"
          >
            Cancel
          </CustomButton>
        </CustomDiv>
      </Menu>
    </>
  );
};

export default Copy;
