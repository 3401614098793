import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomButton from "../../../Shared/CustomButton";
import { Avatar, Divider } from "@mui/material";
import Text from "../../../Shared/Text";
import CustomInput from "../../../Shared/CustomInput";
import CustomDiv from "../../../Shared/CustomDiv";
import { Business, Lock, People } from "@mui/icons-material";

const WorkspaceVisibleMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const workspaceName = localStorage.getItem("current-workspace");

  return (
    <>
      <CustomButton
        variant="text"
        onClick={handleClick}
        startIcon={<People />}
        className="!bg-white !bg-opacity-30 !px-2 !text-sm !capitalize !mx-1 !text-black"
      >
        Workspace Visible
      </CustomButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-1"
      >
        <Text className="text-center p-1">Change visibility</Text>
        <Divider />

        <MenuItem className="flex flex-col !items-start">
          <span className="flex items-center gap-1">
            <Lock className="!text-sm !text-start !text-red-600" />
            <Text>Private</Text>
          </span>
          <Text className="text-xs">
            Only boards members can see and edit this board
          </Text>
        </MenuItem>
        <MenuItem className="flex flex-col !items-start">
          <span className="flex items-center gap-1">
            <People className="!text-sm !text-start !text-gray-500" />
            <Text>Workspace</Text>
          </span>
          <Text className="text-xs ">
            All the members of the{" "}
            <span className="!capitalize">
              {workspaceName.replace("-", " ")}
            </span>
            workspace can <br /> see and edit this board
          </Text>
        </MenuItem>

        <MenuItem className="flex flex-col !items-start" disabled>
          <span className="flex items-center gap-1">
            <Business className="!text-sm !text-start !text-gray-500" />
            <Text>Organization</Text>
          </span>
          <Text className="text-xs ">
            All members of the organization can see this board. <br /> The board
            must be added to an enterprise <br /> Workspace to enable this.
          </Text>
        </MenuItem>
        <MenuItem className="flex flex-col !items-start">
          <span className="flex items-center gap-1">
            <People className="!text-sm !text-start !text-gray-500" />
            <Text>Public</Text>
          </span>
          <Text className="text-xs">
            Anyone on the internet can see this board. <br /> Only board members
            can edit.
          </Text>
        </MenuItem>
      </Menu>
    </>
  );
};

export default WorkspaceVisibleMenu;
