import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import CreateBoard from "./CreateBoard";
import CreateWorkspace from "./CreateWorkspace";
import CustomButton from "../../CustomButton";
import { Add } from "@mui/icons-material";

const CreateMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        variant="text"
        size="small"
        onClick={handleClick}
        startIcon={<Add />}
        className="hover:!bg-white focus:!bg-white focus:!bg-opacity-60 hover:!bg-opacity-20 !capitalize !mx-1 !text-black"
      >
        Create
      </CustomButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-2"
      >
        <CreateBoard from="MainHeader" />
        <CreateWorkspace from="MainHeader"/>
      </Menu>
    </>
  );
};

export default CreateMenu;
