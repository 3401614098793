import * as React from "react";
import Menu from "@mui/material/Menu";
import Text from "../../../Shared/Text";
import CustomDiv from "../../../Shared/CustomDiv";
import { OpenInNew } from "@mui/icons-material";
import { Avatar, Divider, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ProfileMenuHead = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    navigate("/");
  };

  return (
    <>
      <Avatar
        src="broken.jpg"
        alt="MS"
        onClick={handleClick}
        className="!h-8 !w-8 cursor-pointer !text-base !bg-orange-700"
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-2 profile-menu !pt-0"
      >
        <CustomDiv className="flex flex-col">
          <span className="bg-[#1565c0] w-80 h-24"></span>
          <span className="flex relative bottom-12 px-3 gap-2 cursor-pointer">
            <Avatar
              src="broken.jpg"
              alt="MS"
              className="!h-20 !text-black !w-20 !text-4xl !bg-orange-600"
            >
              MS
            </Avatar>

            <span className="text-white">
              <Text className="font-semibold">Mani Kant Sharma</Text>
              <Text className="text-xs">dadzheromani@gmail.com</Text>
            </span>
          </span>
          <MenuItem>Edit Profile Info</MenuItem>
          <Divider />
          <MenuItem>View member’s board activity</MenuItem>
        </CustomDiv>
      </Menu>
    </>
  );
};

export default ProfileMenuHead;
