import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import CustomButton from "../../Shared/CustomButton";
import EmojiPicker from "emoji-picker-react";
import { IconButton } from "@mui/material";
import { AddReaction, EmojiEmotions } from "@mui/icons-material";

const EmojiPickers = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <span
        onClick={handleClick}
        className="!text-gray-500 cursor-pointer hover:!text-gray-700 text-start"
      >
        <AddReaction className="!text-lg" />
      </span>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-1"
      >
        <span className="pickemoji">
          <EmojiPicker />
        </span>
      </Menu>
    </>
  );
};

export default EmojiPickers;
