import {
  ContactSupport,
  DesktopMac,
  ExpandMore,
  KeyboardArrowDown,
  Notifications,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Dropdown } from "antd";
import React from "react";
import WorkspaceListDropdown from "../../Components/Workspace/WorkspaceDropdownList";
import CustomButton from "../CustomButton";
import CustomDiv from "../CustomDiv";
import CustomInput from "../CustomInput";
import Text from "../Text";
import CreateMenu from "./CreateMenu";
import NotificationsMenu from "./NotificationsMenu";
import PrfileMenu from "./ProfileMenu";
import RecentMenu from "./RecentMenu";
import StarredMenu from "./StarredMenu";

const MainHeader = ({ setWorkspaceName }) => {
  const items = [
    {
      label: <span>1st menu item</span>,
      key: "0",
    },
    {
      label: <span>2nd menu item</span>,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: "3rd menu item",
      key: "3",
    },
  ];
  return (
    <div className="w-full h-10 backdrop-blur-2xl">
      <CustomDiv className="flex justify-between gap-3 items-center mr-2 h-full">
        <span className="flex items-center h-full">
          <Text className="text-lg font-semibold ">
            <span className="flex items-center whitespace-nowrap mx-1">
              <DesktopMac className="mx-1 " /> Aara Project Portal
            </span>
          </Text>
          <WorkspaceListDropdown setWorkspaceName={setWorkspaceName} />
          <RecentMenu />
          <StarredMenu />

          <CustomButton
            variant="text"
            size="small"
            endIcon={<KeyboardArrowDown />}
            className="hover:!bg-white focus:!bg-white focus:!bg-opacity-80 hover:!bg-opacity-20 !capitalize !mx-1 !text-black"
          >
            Templates
          </CustomButton>

          <CreateMenu />
        </span>
        <span className="flex items-center gap-2  h-full">
          <CustomInput
            placeholder="Search..."
            className="bg-transparent !p-1 search backdrop-blur placeholder:text-white"
          />
          <NotificationsMenu />
          <IconButton size="small">
            <ContactSupport color="action" />
          </IconButton>

          <PrfileMenu />
        </span>
      </CustomDiv>
    </div>
  );
};

export default MainHeader;
