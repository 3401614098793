import { createTheme, ThemeProvider } from "@mui/material";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignIn from "./Authentication/SignIn";
import Layouts from "./Layout";
import { routes } from "./Routes";
import React from "react";

const App = () => {
  const [theme] = useState("light");
  const mode = createTheme({
    palette: {
      mode: theme,
    },
  });
  return (
    <ThemeProvider theme={mode}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          {routes.map((route) => {
            return (
              <Route
                key={route.id}
                path={route.path}
                element={<Layouts component={route.element} />}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
