import { TextField } from "@mui/material";
import classNames from "classnames";
import React from "react";

const CustomInput = ({
  placeholder = "",
  onChange,
  id = "",
  name = "",
  className = "",
  value,
  status = "",
  label = "",
  multiline = false,
  rows,
  type = "",
  defaultValue = "",
}) => {
  return (
    <TextField
      placeholder={placeholder}
      className={classNames("rounded", className)}
      onChange={onChange}
      value={value}
      id={id}
      defaultValue={defaultValue}
      multiline={multiline}
      rows={rows}
      label={label}
      size="small"
      name={name}
      status={status}
      type={type}
    />
  );
};

export default CustomInput;
