import { People } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import CustomDiv from "../../../../Shared/CustomDiv";
import Text from "../../../../Shared/Text";
import CustomInput from "../../../CustomInput";

const CreateWorkspace = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState();
  const handleClose = () => setIsModalOpen(false);
  const handleOpen = () => setIsModalOpen(true);
  return (
    <>
      <CustomDiv
        onClick={handleOpen}
        className="flex flex-col !text-start w-80 p-2 !px-3 hover:bg-slate-100 focus:bg-slate-200 cursor-pointer "
      >
        <span className="flex !text-start font-bold">
          <People className="mr-1" /> Create Workspace
        </span>
        <Text className="text-sm">
          A Workspace is a group of boards and people. Use it to organize your
          company, side hustle, family, or friends.
        </Text>
      </CustomDiv>

      <Modal open={isModalOpen} onClose={handleClose}>
        <Box className="absolute top-1/2 left-1/2 !rounded !outline-none flex -translate-x-1/2 bg-white -translate-y-1/2 h-5/6 w-3/4 overflow-y-auto">
          <CustomDiv className="w-1/2 p-3 flex flex-col gap-2 justify-center !px-10">
            <Text className="text-2xl font-semibold">
              Let's build a Workspace
            </Text>
            <Text className="text-sm">
              Boost your productivity by making it easier for everyone to access
              boards in one location.
            </Text>
            <CustomDiv className="my-3 flex flex-col gap-1">
              <CustomInput label="Workspace Name" />
              <Text className="text-xs">
                This is the name of your company, team or organization.
              </Text>
            </CustomDiv>

            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                Workspace Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                size="small"
                label="Workspace Type"
                onChange={(event) => setValue(event.target.value)}
              >
                <MenuItem value={1}>Small Business</MenuItem>
                <MenuItem value={2}>Operation</MenuItem>
                <MenuItem value={3}>Marketting</MenuItem>
              </Select>
            </FormControl>
            <CustomDiv className="my-3 flex flex-col gap-1">
              <CustomInput label="Workspace Description" multiline rows={4} />
              <Text className="text-xs">
                Get your members on board with a few words about your Workspace.
              </Text>
            </CustomDiv>
            <Button variant="contained" disabled>
              Continue
            </Button>
          </CustomDiv>
          <CustomDiv className="w-1/2 bg-sky-200 flex justify-center items-center">
            <img
              src="https://a.trellocdn.com/prgb/assets/d1f066971350650d3346.svg"
              alt=""
            />
          </CustomDiv>
        </Box>
      </Modal>
    </>
  );
};
export default CreateWorkspace;
