import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomButton from "../../../Shared/CustomButton";
import { Avatar, Divider } from "@mui/material";
import Text from "../../../Shared/Text";
import CustomInput from "../../../Shared/CustomInput";
import CustomDiv from "../../../Shared/CustomDiv";

const Members = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        onClick={handleClick}
        className="!bg-slate-200 !text-black hover:!bg-slate-300 w-full text-start"
      >
        Members
      </CustomButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-1"
      >
        <Text className="text-center my-1">Members</Text>
        <Divider />
        <CustomDiv className="p-2 flex flex-col gap-2">
          <CustomInput label="Search Members" className="w-full" />
          <Text className="mt-2">Board Members</Text>
          <span className="flex items-center gap-2 cursor-pointer">
            <Avatar
              src="broken.jpg"
              alt="MS"
              className="!h-8 !w-8 !text-base !bg-orange-600"
            >
              MS
            </Avatar>
            <Text className="font-semibold">Mani Kant Sharma</Text>
          </span>
          <MenuItem className="!rounded">Show Other Workspace Members</MenuItem>
        </CustomDiv>
      </Menu>
    </>
  );
};

export default Members;
