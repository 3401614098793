import {
  Avatar,
  Button,
  Divider,
  ListItemButton,
  TextField,
} from "@mui/material";
import React from "react";
import CustomDiv from "../../Shared/CustomDiv";
import Text from "../../Shared/Text";
import LinkIcon from "@mui/icons-material/Link";
import { Link } from "react-router-dom";
import { Close, InfoOutlined } from "@mui/icons-material";
import { Tabs } from "antd";
import BoardHeader from "../../Components/Bords/BoardHeader";

const Members = () => {
  return (
    <>
      <BoardHeader />
      <CustomDiv className="px-5">
        <CustomDiv className="flex flex-col gap-2">
          <Text className="text-2xl font-bold text-black text-opacity-80">
            Members
          </Text>
          <CustomDiv className="flex flex-col w-full">
            <CustomDiv className="w-1/5">
              <Text className="text-sm">Members of Workspace boards</Text>
            </CustomDiv>
            <CustomDiv className="flex flex-col">
              <Tabs
                tabPosition={"left"}
                items={[
                  {
                    label: (
                      <ListItemButton className="!w-52 !rounded-sm">
                        Workspace Members(1)
                      </ListItemButton>
                    ),
                    key: 1,
                    children: (
                      <>
                        <Text className="text-2xl font-bold text-black text-opacity-80">
                          Workspace Members(1)
                        </Text>
                        <Text className="text-sm my-3 text-black text-opacity-80">
                          Workspace members can view and join all Workspace
                          visible boards and create new boards in the Workspace.
                        </Text>
                        <Divider />
                        <Text className="text-2xl font-bold text-black text-opacity-80 mt-5">
                          Invite members to join to you
                        </Text>
                        <span className="flex justify-between my-2">
                          <Text className="text-sm my-3 text-black text-opacity-80">
                            Anyone with an invite link can join this Free
                            Workspace. <br />
                            You can also disable and create a new invite link
                            for this Workspace at any time.
                          </Text>
                          <span className="flex gap-2 items-center">
                            <Button
                              className="!capitalize !bg-white !bg-opacity-20"
                              size="small"
                            >
                              Disable Invite Link
                            </Button>
                            <Button
                              className="!capitalize !bg-white !bg-opacity-20"
                              size="small"
                            >
                              <LinkIcon className="mr-1" /> Invite With Link
                            </Button>
                          </span>
                        </span>
                        <Divider />
                        <TextField
                          size="small"
                          placeholder="Search by name"
                          className="!rounded-sm !my-5 !w-72"
                        />
                        <Divider />
                        <CustomDiv className="flex justify-between my-3">
                          <span className="flex gap-2 items-center">
                            <Avatar
                              className="!rounded !bg-gradient-to-t !from-yellow-600 !to-red-600"
                              src="broken.jpg"
                              alt="Mani Kant Sharma"
                            />
                            <span className="flex flex-col">
                              <Text className="whitespace-nowrap">
                                Mani Kant Sharma
                              </Text>
                              <Text className="whitespace-nowrap text-xs">
                                @manikantsharma
                              </Text>
                            </span>
                          </span>
                          <span className="flex gap-3 items-center">
                            <Link to="#" className="!text-xs underline">
                              On 1 board
                            </Link>
                            <Button
                              className="!capitalize !bg-white !bg-opacity-20"
                              size="small"
                            >
                              Admin <InfoOutlined className="ml-1 !text-xs" />
                            </Button>
                            <Button
                              className="!capitalize !bg-white !bg-opacity-20"
                              size="small"
                            >
                              <Close className="mr-1 !text-xs" /> Leave
                            </Button>
                          </span>
                        </CustomDiv>
                      </>
                    ),
                  },
                  {
                    label: (
                      <ListItemButton className="!w-52 !rounded-sm">
                        Guest(1)
                      </ListItemButton>
                    ),
                    key: 2,
                    children: (
                      <>
                        <Text className="text-2xl font-bold text-black text-opacity-80">
                          Guest(1)
                        </Text>
                        <Text className="text-sm my-3 text-black text-opacity-80">
                          Workspace members can view and join all Workspace
                          visible boards and create new boards in the Workspace.
                        </Text>

                        <Divider />
                        <TextField
                          size="small"
                          placeholder="Search by name"
                          className="!rounded-sm !my-5 !w-72"
                        />
                        <Divider />
                        <CustomDiv className="flex justify-between my-3">
                          <span className="flex gap-2 items-center">
                            <Avatar
                              className="!rounded !bg-gradient-to-t !from-yellow-600 !to-red-600"
                              src="broken.jpg"
                              alt="Mani Kant Sharma"
                            />
                            <span className="flex flex-col">
                              <Text className="whitespace-nowrap">
                                Mani Kant Sharma
                              </Text>
                              <Text className="whitespace-nowrap text-xs">
                                @manikantsharma
                              </Text>
                            </span>
                          </span>
                          <span className="flex gap-3 items-center">
                            <Link to="#" className="!text-xs underline">
                              On 1 board
                            </Link>
                            <Button
                              className="!capitalize !bg-white !bg-opacity-20"
                              size="small"
                            >
                              Admin <InfoOutlined className="ml-1 !text-xs" />
                            </Button>
                            <Button
                              className="!capitalize !bg-white !bg-opacity-20"
                              size="small"
                            >
                              <Close className="mr-1 !text-xs" /> Leave
                            </Button>
                          </span>
                        </CustomDiv>
                      </>
                    ),
                  },
                  {
                    label: (
                      <ListItemButton className="!w-52 !rounded-sm">
                        Pending(1)
                      </ListItemButton>
                    ),
                    key: 3,
                    children: (
                      <>
                        <Text className="text-2xl font-bold text-black text-opacity-80">
                          Pendig(1)
                        </Text>
                        <Text className="text-sm my-3 text-black text-opacity-80">
                          Workspace members can view and join all Workspace
                          visible boards and create new boards in the Workspace.
                        </Text>
                        <Divider />

                        <TextField
                          size="small"
                          placeholder="Search by name"
                          className="!rounded-sm !my-5 !w-72"
                        />
                        <Divider />
                        <CustomDiv className="flex justify-between my-3">
                          <span className="flex gap-2 items-center">
                            <Avatar
                              className="!rounded !bg-gradient-to-t !from-yellow-600 !to-red-600"
                              src="broken.jpg"
                              alt="Mani Kant Sharma"
                            />
                            <span className="flex flex-col">
                              <Text className="whitespace-nowrap">
                                Mani Kant Sharma
                              </Text>
                              <Text className="whitespace-nowrap text-xs">
                                @manikantsharma
                              </Text>
                            </span>
                          </span>
                          <span className="flex gap-3 items-center">
                            <Link to="#" className="!text-xs underline">
                              On 1 board
                            </Link>
                            <Button
                              className="!capitalize !bg-white !bg-opacity-20"
                              size="small"
                            >
                              Admin <InfoOutlined className="ml-1 !text-xs" />
                            </Button>
                            <Button
                              className="!capitalize !bg-white !bg-opacity-20"
                              size="small"
                            >
                              <Close className="mr-1 !text-xs" /> Leave
                            </Button>
                          </span>
                        </CustomDiv>
                      </>
                    ),
                  },
                ]}
              />
            </CustomDiv>
          </CustomDiv>
        </CustomDiv>
      </CustomDiv>
    </>
  );
};

export default Members;
