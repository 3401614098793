import { Edit, Lock } from "@mui/icons-material";
import { Avatar, Divider } from "@mui/material";
import React from "react";

import CustomDiv from "../../../Shared/CustomDiv";
import Text from "../../../Shared/Text";
import InviteMembers from "../../Members/InviteMembers";

const BoardHeader = () => {
  const workspaceName = localStorage.getItem("current-workspace");
  return (
    <>
      <CustomDiv className="flex justify-around my-8">
        <CustomDiv className="flex gap-2 items-center">
          <Avatar
            className="!rounded !h-14 !w-14 !bg-gradient-to-t !from-yellow-600 !to-red-600 cursor-pointer"
            src="broken.jpg"
            alt="Project Manager"
          />
          <span>
            <Text className="font-semibold text-xl capitalize">
              {workspaceName?.replace("-", " ")}
              <Edit className="!text-base !text-gray-600 hover:!text-black cursor-pointer mx-2" />
            </Text>
            <span className="flex items-center">
              <Lock className="!text-xs !text-gray-600 hover:!text-black cursor-pointer mr-1" />
              <Text className="!text-xs"> Private</Text>
            </span>
          </span>
        </CustomDiv>
        <span>
          <InviteMembers from="MainHeader"/>
        </span>
      </CustomDiv>
      <Divider />
    </>
  );
};

export default BoardHeader;
