import React from "react";
import { useParams } from "react-router-dom";
import CustomDiv from "../../Shared/CustomDiv";

const Workspace = ({ component }) => {
  const { workspaceName } = useParams();

  const emptyFunction = () => {};
  React.useEffect(() => {
    workspaceName !== undefined
      ? localStorage.setItem("current-workspace", workspaceName)
      : emptyFunction();
  }, [workspaceName]);
  return (
    <CustomDiv className="px-5">
     
      {component}
    </CustomDiv>
  );
};

export default Workspace;
